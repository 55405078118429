@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: normal;
  font-style: normal;
  /* font-family: sans-serif; */
}