body {
  overflow-x: hidden;
}

#root {
  display: flex;
  overflow-x: hidden;
}

.react-clock {
  background-color: #fff;
  border-radius: 50%
}

.react-clock__face {
  border: 3px solid white
}

.react-clock__second-hand {
  transition: transform cubic-bezier(.68,0,.27,1.55) .2s
}

.react-clock__second-hand__body:after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 0;
  left: 50%;
  background-color: #BF964B;
  border-radius: 50%;
  transform: translate(-50%)
}

.fade-enter {
  transform: translateY(100px);
}

.fade-enter-active {
 transform: translateY(0);
  transition: transform 300ms;
}

.fade-exit {
  transform: translateY(0);
}

.fade-exit-active {
  transform: translateY(100px);
  transition: transform 300ms;
}

.admin-layout > div:nth-of-type(3) {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.calendar-event > div:nth-of-type(1) {
  display: flex;
  flex: 1;
}

.react-calendar {
  width: 100% !important;
  border-radius: 10px;
  border: none !important;
  /* @apply border-[3px] border-main !important */
}

.react-calendar abbr {
  font-size: 16px;
}
.react-calendar button {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.react-calendar__tile--active {
  @apply bg-main !important
}

.react-calendar button {
  border: 1px dotted #d6d7da !important;
}

.react-calendar__navigation > button {
  border: none !important;
}

.no-border {
  
}

.reserved {
  background-color: red !important;
}

table th {
  padding: 10px 7px;
  background-color: rgb(77, 77, 77);
  color: white;
  white-space: nowrap;
  text-align: left;
}
td {
  white-space: nowrap;
}

table td {
  padding: 7px;
}

table thead, table th {
}

table {
  border-radius: 0.17rem;
  overflow: hidden;
}

table tr:hover td {
  background-color: rgb(169, 169, 169) !important;
}

tr:nth-child(odd), tr:nth-child(odd) td{  
  background-color: rgb(220, 220, 220);
  z-index: 99;
}

tr:nth-child(even), tr:nth-child(even) td{  
  background-color: rgb(191, 191, 191);
  z-index: 99;
}

.input {
  @apply p-3 border-[1px] border-black w-full
}

.dim {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.alert-box {
  padding: 20px;
  border-radius: 10px;
  transform: scale(0.7);
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  animation: pop 0.3s ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes pop {
  to {
    transform: scale(1);
  }
}

.alert-box h3 {
  font-size: 25px;
}

.alert-box p {
  font-size: 18px;
}

.alert-box .btns {
  display: flex;
  justify-content: space-between;
  align-self: stretch;
  gap: 30px;
}

.btns button {
  padding: 15px 25px;
  font-size: 18px;
  font-weight: bold;
  @apply bg-main;
}

.btns button:last-of-type {
  background-color: white;
  border: 1px solid black;
  @apply border-main;
}

th {
  position: sticky;
  text-align: center !important;
  top: 0;
  z-index: 9999;
}

td {
  text-align: center;
  @apply text-sm
}

tr {
  z-index: 999;
}
.cb-container > div {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}
.infinite-scroll-component {
  overflow: initial !important;
}

@media (max-width: 768px) {
  .react-calendar {
    width: auto !important;
  }
  .form p {
    font-size: 12px;
  }
  .form button {
    font-size: 12px;
    padding: 5px;
    white-space: nowrap;
  }

  .total {
    font-size: 16px !important;
  }
  table td {
    padding: 4px;
  }
  th {
    font-size: 14px;
  }
}
